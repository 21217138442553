export const items = {
  header: {
    title: 'İşinin Ustalarıyla Tanış',
    description:
      'Evini boyatacak veya tadilat mı yaptıracaksın? Onlarca hizmet arasından sana uygununu seç ve hizmet talebi oluştur!',
  },
  steps: [
    {
      order: 1,
      title: 'Hizmet Talebi Oluştur',
      image: 'first.png',
      image2x: 'first@2x.png',
      desc: 'Dilediğin hizmet türünü seçerek ihtiyacın olan hizmetin detaylarını girebilirsin.',
    },
    {
      order: 2,
      title: 'Teklifleri Değerlendir',
      image: 'second.png',
      image2x: 'second@2x.png',
      desc: 'Gelen teklifleri değerlendirerek senin için en uygun teklifi seçebilirsin.',
    },
    {
      order: 3,
      title: 'Hemen İşini Hallet!',
      image: 'third.png',
      image2x: 'third@2x.png',
      desc: 'Teklifini seçtiğin hizmet verenle kolayca iletişim kurabilir ve hizmet işini yaptırabilirsin.',
    },
  ],
}

export const itemsEmployer = {
  header: {
    title: 'İlan Vermek Sadece 1 Dakika!',
    description:
      'Çalışan ihtiyacın için dakikalar içinde ilan oluşturabilir, işin konumuna yakın oturan çalışanlara hızlıca ulaşabilirsin.',
  },
  steps: [
    {
      order: 1,
      title: 'Uygulamayı İndir, Üye Ol veya Giriş Yap',
      image: 'first-employer.png',
      image2x: 'first-employer@2x.png',
      desc: 'İşin Olsun Uygulamasına telefon numaranla giriş yap, “Çalışan Aıyorum”u seçerek ilerle.',
    },
    {
      order: 2,
      title: 'Ücretsiz İlan Ver',
      image: 'second-employer.png',
      image2x: 'second-employer@2x.png',
      desc: 'Çalışma türünü ve işin detaylarını gir, hızlıca ilanını yayınla.',
    },
    {
      order: 3,
      title: 'Aradığın Çalışanı Hızlıca Bul',
      image: 'third-employer.png',
      image2x: 'third-employer@2x.png',
      desc: 'Gelen başvuralar arasında adaylarla kolaylıkla iletişim kur ve aradığın çalışanı bul!',
    },
  ],
}
