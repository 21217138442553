import { memo, useRef, useEffect, useState } from 'react'
import Carousel from '@brainhubeu/react-carousel'
import styles from './index.module.scss'

const slidePerPageDesktop = 3

const BlogContent = memo((props) => {
  const { blogposts, caption, link } = props
  const [activeSlide, setActiveSlide] = useState(0)

  const scrollLockRef = useRef(null)
  const breakpoints = {
    320: { slidesPerPage: 1 },
    360: { offset: -30 },
    375: { offset: -45 },
    390: { offset: -55 },
    415: { offset: -65 },
    430: { offset: -95 },
    640: { slidesPerPage: 1 },
    758: { slidesPerPage: 2, centered: true },
    768: { slidesPerPage: 2, centered: true, offset: -50 },
    850: { slidesPerPage: 2, centered: true, offset: -70 },
    991: { slidesPerPage: 2, centered: true, offset: -100 },
    1180: { slidesPerPage: 1, offset: 200, centered: true },
  }

  useEffect(() => {
    const handleNvEnter = (event) => {
      if (event.touches.length > 0) {
        scrollLockRef.current.firstClientX = event.touches[0].clientX
        scrollLockRef.current.firstClientY = event.touches[0].clientY
      }
    }
  
    const handleNvMove = (event) => {
      if (event.touches.length > 0) {
        const minValue = 10
        const maxValue = -10
  
        const clientX =
          event.touches[0].clientX - scrollLockRef.current.firstClientX
        const clientY =
          event.touches[0].clientY - scrollLockRef.current.firstClientY
  
        if (
          Math.abs(clientY) > Math.abs(clientX) + minValue ||
          Math.abs(clientY) > Math.abs(clientX) + maxValue
        ) {
          return
        }
  
        event.preventDefault()
      }
    }
  
    const element = scrollLockRef.current
    if (!element) return
  
    element.addEventListener('touchstart', handleNvEnter)
    element.addEventListener('touchmove', handleNvMove, { passive: false })
  
    return () => {
      element.removeEventListener('touchstart', handleNvEnter)
      element.removeEventListener('touchmove', handleNvMove)
    }
  }, [])
  

  const returnImageUrl = (item) => {
    const images = item?.yoast_head_json?.og_image
    if (Array.isArray(images) && images.length > 0) return images[0].url
    else
      return (
        process.env.NEXT_PUBLIC_MAIN_URL2 +
        '/_next/static/images/company-profile-company.png'
      )
  }

  const renderBlogPost = (item, index) => (
    <div className={styles.wrapper} key={index}>
      <a href={item?.link}>
        <div className={styles.card}>
          <img
            src={returnImageUrl(item)}
            alt={item?.title?.rendered}
            loading="lazy"
          />
          <h3 dangerouslySetInnerHTML={{ __html: item?.title?.rendered }} />
          <div
            dangerouslySetInnerHTML={{
              __html: `<p>${item?.content?.rendered
                .replace(/<\/?[^>]+(>|$)/g, '')
                .substring(0, 150)}...</p>`,
            }}
          />
          <div className={styles.bottom}>
            <div className={styles.date}>
              {new Date(item?.date?.split('T')[0]).toLocaleDateString('tr-TR')}
            </div>
            <div className={styles['read-more']}>
              Devamını Oku
              <i className="icon-arrow-forward"></i>
            </div>
          </div>
        </div>
      </a>
    </div>
  )

  return (
    <section className={`${styles['blog-posts']} container`}>
      <h2>{caption}</h2>
      <div className="d-none d-lg-block">
        <Carousel
          keepDirectionWhenDragging
          dots
          addArrowClickHandler
          animationSpeed={1000}
          breakpoints={breakpoints}
          arrowLeft={
            <div className={`${styles.swipe} ${styles.left}`}>
              <i className="icon icon-angle-left"></i>
            </div>
          }
          arrowRight={
            <div className={`${styles.swipe} ${styles.right}`}>
              <i className="icon icon-angle-right"></i>
            </div>
          }
          onChange={setActiveSlide}
          arrowRightDisabled={
            activeSlide ===
            Math.ceil(blogposts.length / slidePerPageDesktop) - 1
          }
          arrowLeftDisabled={activeSlide === 0}
        >
          {[0, 3, 6].map((start, index) => (
            <div key={index} className={styles.wrapper}>
              {blogposts.slice(start, start + 3).map(renderBlogPost)}
            </div>
          ))}
        </Carousel>
      </div>

      <div ref={scrollLockRef} className="d-block d-lg-none">
        <Carousel
          keepDirectionWhenDragging
          dots
          animationSpeed={1000}
          breakpoints={breakpoints}
        >
          {blogposts.map(renderBlogPost)}
        </Carousel>
      </div>

      <div className={styles.redirect}>
        <a
          href={`https://isinolsun.com/blog/${link || ''}`}
          className={styles.button}
        >
          Tümünü Gör
        </a>
      </div>
    </section>
  )
})

export default BlogContent
